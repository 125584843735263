import { APIRequestStatus } from 'core/constants/redux';
import {
  ObjectActionType,
  SourceTypes,
  Types,
} from 'redux-v2/report-listing/report-listing.state';
import { DeviceType } from 'core/models/store';
import { GlobalStoreActions, IGlobalStoreState } from './global-store.state';

const initialGlobalStoreState: IGlobalStoreState = {
  requestProcessing: {
    [GlobalStoreActions.GET_ALL_TAGS_REQUEST]: APIRequestStatus.AboutToLoad,
    [GlobalStoreActions.GET_PROFILE_LIST_REQUEST]: APIRequestStatus.AboutToLoad,
    [GlobalStoreActions.EDIT_TAGS_BY_OBJECTID_REQUEST]: APIRequestStatus.AboutToLoad,
    [GlobalStoreActions.EDIT_PROFILES_TO_DASHBOARD_REQUEST]: APIRequestStatus.AboutToLoad,
    [GlobalStoreActions.EDIT_PROFILES_TO_REPORTS_REQUEST]: APIRequestStatus.AboutToLoad,
    [GlobalStoreActions.GET_TAGS_BY_OBJECT_REQUEST]: APIRequestStatus.AboutToLoad,
  },
  tags: {
    globaltags: [],
    updatedTagsList: [],
    activeTagsList: [],
    tagItemInfo: {
      ObjectId: '',
      ObjectType: ObjectActionType.Report,
      Id: '',
      SourceType: SourceTypes.SIERA,
      Type: Types.Internal,
    },
  },
  profiles: {
    availableProfiles: [],
    updatedProfilesList: [],
    reportDetails: {
      Id: '',
      SourceType: SourceTypes.SIERA,
      Type: Types.Internal,
    },
  },
  updateNavBarCollapse: false,
  showErrorBoundary: false,
  selectedPage: '',
  deviceType: DeviceType.DESKTOP,
};

export interface IActionInterface<T> {
  type: GlobalStoreActions,
  payload: T,
}

export default initialGlobalStoreState;
