import React, { ReactNode } from 'react';
import Tooltip from 'rc-tooltip';
import { mouseEnterDelay } from 'components/feature/Report/ReportSidebar/common/constants';
import { TooltipPlacement } from 'core/constants/report';
import StyledSecondaryButton from './secondary-button.style';
import useLocalize from '../Localization/useLocalize.hook';

interface ISecondaryButtonProps {
  onClickHandler: () => void;
  displayName?:ReactNode;
  classNames?: Array<string>;
  wrapperClassName?: Array<string>;
  uniqueKey: string;
  isDisable?: boolean;
  tooltip?:string;
  tooltipPlacement?:string;
}

const SecondaryButton = (props: ISecondaryButtonProps) => {
  const {
    onClickHandler,
    classNames,
    displayName,
    uniqueKey,
    wrapperClassName,
    isDisable,
    tooltip,
    tooltipPlacement,
  } = props;
  return (
    <StyledSecondaryButton
      key={uniqueKey}
      className={`${wrapperClassName.join(' ')}`}
    >
      <Tooltip
        placement={tooltipPlacement}
        overlay={<span>{tooltip || displayName}</span>}
        mouseEnterDelay={mouseEnterDelay}
        overlayClassName="se-dark-theme-tooltip"
      >
        <button
          type="button"
          className={`se-secondary-changes ${
            isDisable ? 'se-button-disabled' : ''
          } ${classNames.join(' ')}`}
          onClick={() => !isDisable && onClickHandler()}
        >
          {displayName}
        </button>
      </Tooltip>
    </StyledSecondaryButton>
  );
};

SecondaryButton.defaultProps = {
  classNames: [],
  wrapperClassName: [],
  displayName: `${useLocalize('cmn.cancel')}`,
  isDisable: false,
  tooltip: '',
  tooltipPlacement: TooltipPlacement.Left,
};

export default SecondaryButton;
