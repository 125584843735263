import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AuthReduxModel, AuthResponseModel, ObjModel,
} from 'core/models';
import CacheItem from 'core/constants/cache';
import Cache from 'core/cache/cache';
import { MobileAuthInfo } from 'core/models/store';
import { MobileAuthExceptions } from 'core/models/auth-response';
import { AuthStoreActions } from './auth-store-state';
import InitialAuthState from './auth-store-initial-state';
import { getDisabledFeatures, processOrgAttributes } from './auth-store.util';

const authStoreSlice = createSlice({
  name: 'authStore',
  initialState: InitialAuthState(),
  reducers: {
    [AuthStoreActions.SET_AUTH]: (state:AuthReduxModel.IState, action:PayloadAction<AuthResponseModel.Auth | null>) => {
      const auth = action.payload;
      if (auth) {
        const {
          Token, ExpiresOn, UserAttributes, OrgAttributes,
        } = auth;
        const newOrgAttributes = processOrgAttributes(OrgAttributes);
        Cache.setItem(CacheItem.Auth, Token);
        Cache.setItem(CacheItem.AuthExpiry, ExpiresOn);
        Cache.setItem(CacheItem.UserAttr, UserAttributes);
        Cache.setItem(CacheItem.OrgAttr, newOrgAttributes);
        state.token = Token;
        state.expiresOn = ExpiresOn;
        state.userAttributes = UserAttributes;
        state.orgAttributes = newOrgAttributes;
        state.variables = {
          SER_USER_ID: UserAttributes ? UserAttributes.Id : '',
          SER_USER_AUTO_ID: UserAttributes ? UserAttributes.AutoId : 0,
        };
        state.isReportingDisabled = UserAttributes?.IsReportingDisabled;
        state.isSieraEnabled = UserAttributes?.isSieraEnabled;
        state.disabledFeatures = getDisabledFeatures(UserAttributes);
        state.mobileAuthInfo = {
          ExceptionType: MobileAuthExceptions.success,
          source: UserAttributes?.source,
          statusCode: 200,
        };
      } else {
        Cache.clear();
        state.token = null;
        state.expiresOn = -1;
        state.userAttributes = null;
        state.orgAttributes = null;
      }
    },
    [AuthStoreActions.SET_MARVIN_AUTH]: (state, action:PayloadAction<boolean>) => {
      state.isMarvin = action.payload;
    },
    [AuthStoreActions.SET_V1_REPORT_CONTEXT]: (state, action:PayloadAction<boolean>) => {
      state.isV1Report = action.payload;
    },
    [AuthStoreActions.SET_STATUS_ERROR_CODE]: (state, action:PayloadAction<number>) => {
      state.statusCode = action.payload;
    },
    [AuthStoreActions.SET_ACTIVE_INTERVAL]: (state, action:PayloadAction<number>) => {
      const interval = action.payload;
      clearInterval(state.activeInterval);
      state.activeInterval = interval;
    },
    [AuthStoreActions.SET_ISEMBEDDED_CONTEXT]: (state, action:PayloadAction<boolean>) => {
      state.isEmbedded = action.payload;
    },
    [AuthStoreActions.SET_SETTINGS]: (state, action:PayloadAction<ObjModel.Obj>) => {
      state.settings = action.payload;
    },
    [AuthStoreActions.SET_MOBILE_AUTH_INFO]: (state, action: PayloadAction<MobileAuthInfo>) => {
      state.mobileAuthInfo = action.payload;
    },
  },
});

export const {
  [AuthStoreActions.SET_AUTH]: setAuth,
  [AuthStoreActions.SET_MARVIN_AUTH]: setMarvinAuth,
  [AuthStoreActions.SET_V1_REPORT_CONTEXT]: setV1ReportContext,
  [AuthStoreActions.SET_STATUS_ERROR_CODE]: setStatusErrorCode,
  [AuthStoreActions.SET_ACTIVE_INTERVAL]: setActiveInterval,
  [AuthStoreActions.SET_ISEMBEDDED_CONTEXT]: setIsEmbeddedContext,
  [AuthStoreActions.SET_SETTINGS]: setSettings,
  [AuthStoreActions.SET_MOBILE_AUTH_INFO]: setMobileAuthInfo,
} = authStoreSlice.actions;

export default authStoreSlice;
