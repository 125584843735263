import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'core/constants/redux';
import { ISelectFilterOptions, ISelectFilterResponse } from 'core/models/filter';
import {
  deactivateSchedule,
  getScheduleHistory,
  getScheduleLimits, getScheduleList, getScheduleOwnerNames, getScheduleReportNames, saveScheduleConfig,
  updateScheduleConfig,
} from './schedule-builder.requests';
import {
  IScheduleBuilderLimits, IScheduleBuilderState, IScheduleListPayload, IScheduleListResponse, ScheduleAuditEntry, ScheduleBuilderActionTypes,
} from './schedule-builder.state';
import initializeScheduleBuilderState from './schedule-builder.initial.state';

const scheduleBuilderSlice = createSlice({
  name: 'scheduleBuilder',
  initialState: initializeScheduleBuilderState,
  reducers: {
    [ScheduleBuilderActionTypes.SAVE_SCHEDULE_CONFIG]: (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.SAVE_SCHEDULE_CONFIG] = APIRequestStatus.Processing;
    },
    [ScheduleBuilderActionTypes.GET_AVAILABLE_SLOTS]: (state: IScheduleBuilderState, action:PayloadAction<Array<[number, number]>>) => {
      state.availableSlots = action.payload;
    },
    [ScheduleBuilderActionTypes.TOGGLE_SCHEDULE_FILTER_DRAWER]: (state: IScheduleBuilderState) => {
      state.isScheduleFilterOpen = !state.isScheduleFilterOpen;
    },
    [ScheduleBuilderActionTypes.SET_LIST_FILTERS]: (state: IScheduleBuilderState, action: PayloadAction<IScheduleListPayload>) => {
      // Update the state with the new listPayload object
      state.scheduleListPayload = action.payload;
    },
    [ScheduleBuilderActionTypes.DEACTIVATE_SCHEDULE]: (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.DEACTIVATE_SCHEDULE] = APIRequestStatus.Processing;
    },
    [ScheduleBuilderActionTypes.UPDATE_SCHEDULE_INFO]: (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.UPDATE_SCHEDULE_INFO] = APIRequestStatus.Processing;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveScheduleConfig.pending, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.SAVE_SCHEDULE_CONFIG] = APIRequestStatus.Processing;
    });
    builder.addCase(saveScheduleConfig.fulfilled, (state: IScheduleBuilderState, action: PayloadAction<string>) => {
      state.requestProcessing[ScheduleBuilderActionTypes.SAVE_SCHEDULE_CONFIG] = APIRequestStatus.Success;
      state.scheduleId = action.payload;
    });
    builder.addCase(saveScheduleConfig.rejected, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.SAVE_SCHEDULE_CONFIG] = APIRequestStatus.Failure;
    });

    builder.addCase(updateScheduleConfig.pending, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.UPDATE_SCHEDULE_INFO] = APIRequestStatus.Processing;
    });
    builder.addCase(updateScheduleConfig.fulfilled, (state: IScheduleBuilderState, action: PayloadAction<string>) => {
      state.requestProcessing[ScheduleBuilderActionTypes.UPDATE_SCHEDULE_INFO] = APIRequestStatus.Success;
      state.scheduleId = action.payload;
    });
    builder.addCase(updateScheduleConfig.rejected, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.UPDATE_SCHEDULE_INFO] = APIRequestStatus.Failure;
    });

    builder.addCase(getScheduleLimits.pending, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_LIMITS] = APIRequestStatus.Processing;
    });
    builder.addCase(getScheduleLimits.fulfilled, (state: IScheduleBuilderState, action: PayloadAction<IScheduleBuilderLimits>) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_LIMITS] = APIRequestStatus.Success;
      state.scheduleLimits = action.payload;
    });
    builder.addCase(getScheduleLimits.rejected, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_LIMITS] = APIRequestStatus.Failure;
    });

    builder.addCase(getScheduleList.pending, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_LIST] = APIRequestStatus.Processing;
    });
    builder.addCase(getScheduleList.fulfilled, (state: IScheduleBuilderState, action: PayloadAction<IScheduleListResponse>) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_LIST] = APIRequestStatus.Success;
      state.scheduleList = action.payload;
    });
    builder.addCase(getScheduleList.rejected, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_LIST] = APIRequestStatus.Failure;
    });

    builder.addCase(getScheduleReportNames.pending, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_REPORT_NAMES] = APIRequestStatus.Processing;
    });
    builder.addCase(getScheduleReportNames.fulfilled, (state: IScheduleBuilderState, action: PayloadAction<Array<ISelectFilterResponse>>) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_REPORT_NAMES] = APIRequestStatus.Success;
      const updatedReportNames: Array<ISelectFilterOptions> = [];
      action.payload?.forEach((reportName) => {
        const reportObject = {
          label: reportName.DisplayName,
          value: reportName.Value,
        };
        updatedReportNames.push(reportObject);
      });
      state.scheduleReportNames = updatedReportNames;
    });
    builder.addCase(getScheduleReportNames.rejected, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_REPORT_NAMES] = APIRequestStatus.Failure;
    });

    builder.addCase(getScheduleOwnerNames.pending, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_OWNER_NAMES] = APIRequestStatus.Processing;
    });
    builder.addCase(getScheduleOwnerNames.fulfilled, (state: IScheduleBuilderState, action: PayloadAction<Array<ISelectFilterResponse>>) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_OWNER_NAMES] = APIRequestStatus.Success;
      const updatedOwner: Array<ISelectFilterOptions> = [];
      action.payload?.forEach((ownerName) => {
        const ownerObject = {
          label: ownerName.DisplayName,
          value: ownerName.Value,
        };
        updatedOwner.push(ownerObject);
      });
      state.scheduleOwnerNames = updatedOwner;
    });
    builder.addCase(getScheduleOwnerNames.rejected, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_OWNER_NAMES] = APIRequestStatus.Failure;
    });

    builder.addCase(deactivateSchedule.pending, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.DEACTIVATE_SCHEDULE] = APIRequestStatus.Processing;
    });
    builder.addCase(deactivateSchedule.fulfilled, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.DEACTIVATE_SCHEDULE] = APIRequestStatus.Success;
    });
    builder.addCase(deactivateSchedule.rejected, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.DEACTIVATE_SCHEDULE] = APIRequestStatus.Failure;
    });

    builder.addCase(getScheduleHistory.pending, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_HISTORY] = APIRequestStatus.Processing;
    });
    builder.addCase(getScheduleHistory.fulfilled, (state: IScheduleBuilderState, action: PayloadAction<Array<ScheduleAuditEntry>>) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_HISTORY] = APIRequestStatus.Success;
      state.scheduleHistory = action.payload;
    });
    builder.addCase(getScheduleHistory.rejected, (state: IScheduleBuilderState) => {
      state.requestProcessing[ScheduleBuilderActionTypes.GET_SCHEDULE_HISTORY] = APIRequestStatus.Failure;
    });
  },
});

export const {
  [ScheduleBuilderActionTypes.SAVE_SCHEDULE_CONFIG]: saveScheduleConfigAction,
  [ScheduleBuilderActionTypes.GET_AVAILABLE_SLOTS]: getAvailableSlotsAction,
  [ScheduleBuilderActionTypes.TOGGLE_SCHEDULE_FILTER_DRAWER]: toggleScheduleFilterDrawer,
  [ScheduleBuilderActionTypes.SET_LIST_FILTERS]: setListPayload,
  [ScheduleBuilderActionTypes.DEACTIVATE_SCHEDULE]: deactivateScheduleAction,
  [ScheduleBuilderActionTypes.UPDATE_SCHEDULE_INFO]: updateScheduleConfigAction,
} = scheduleBuilderSlice.actions;

export default scheduleBuilderSlice;
