import styled from 'styled-components';

const StyledPrimaryButton = styled.div`

    button {
      flex: 1;
      margin: 0 6px;
      padding: 8px 16px;
      font-size: 12px;
      font-family: Regular;
      color: var(--secondaryColor);
      border-radius: 2px;
      font-family: SemiBold;
      outline: none;

      &.se-primary-changes  {
          border: 1px solid var(--border);
          color: var(--white);
          background-color: var(--border);
          border-width:0px ;

          :hover {
            background-color:var(--filterBackgroundColor);
          }

          :active {
            background-color: var(--filterBackgroundColor);
          }

          &.se-button-disabled {
            color: var(--white);
            background-color: var(--disablefilterColor);
            cursor: not-allowed;
            border-width: 0px;
          }
        }
    }
`;

export default StyledPrimaryButton;
