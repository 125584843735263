import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'core/constants/redux';
import { IEditTagsData, ITagsData, TagByObject } from 'redux-v2/report-listing/report-listing.state';
import { getEditTagsList } from 'redux-v2/report-listing/report-listing.util';
import { IEditProfileItem, IProfileItem } from 'core/models/profile';
import { DeviceType } from 'core/models/store';
import {
  getProfileList, getTagsList, getUpdatedProfileList, getUpdatedTagList,
} from './global-store.util';
import initialGlobalStoreState from './global-store-initial-state';
import {
  editProfileToReportsRequest, editProfilesToDashboardRequest, editTagsByObjectRequest, getAllTagsRequest, getProfilesListRequest, getTagsByObjectIdRequest,
} from './global-store-requests';
import {
  GlobalStoreActions, IGlobalStoreState,
} from './global-store.state';

const globalStoreSlice = createSlice({
  name: 'globalStore',
  initialState: initialGlobalStoreState,
  reducers: {
    [GlobalStoreActions.UPDATE_NAVBAR_COLLAPSE]: (state:IGlobalStoreState, action:PayloadAction<boolean>) => {
      state.updateNavBarCollapse = action.payload;
    },
    [GlobalStoreActions.UPDATE_TAG_SELECTION]: (state, action:PayloadAction<IEditTagsData>) => {
      state.tags.updatedTagsList = getUpdatedTagList(
        state,
        action.payload.Id,
        action.payload.checked,
      );
    },
    [GlobalStoreActions.UPDATE_PROFILE_SELECTION]: (state:IGlobalStoreState, action: PayloadAction<IEditProfileItem>) => {
      state.profiles.updatedProfilesList = getUpdatedProfileList(
        state,
        action.payload.Id,
        action.payload.checked,
        action.payload.IsPinnedInProfile,
        action.payload.IsHomeInProfile,
      );
    },
    [GlobalStoreActions.RESET_PROFILE_SELECTION]: (state:IGlobalStoreState) => {
      state.profiles.updatedProfilesList = getProfileList(state.profiles.availableProfiles || []);
      state.requestProcessing[GlobalStoreActions.EDIT_PROFILES_TO_DASHBOARD_REQUEST] = APIRequestStatus.AboutToLoad;
      state.requestProcessing[GlobalStoreActions.EDIT_PROFILES_TO_REPORTS_REQUEST] = APIRequestStatus.AboutToLoad;
    },
    [GlobalStoreActions.RESET_TAGS_SELECTION]: (state:IGlobalStoreState) => {
      state.tags.updatedTagsList = getTagsList(state.tags.globaltags || []);
      state.tags.activeTagsList = getTagsList(state.tags.globaltags || []);
      state.requestProcessing[GlobalStoreActions.EDIT_TAGS_BY_OBJECTID_REQUEST] = APIRequestStatus.AboutToLoad;
    },

    [GlobalStoreActions.RESET_GLOBAL_TAGS_AND_PROFILES]: (state:IGlobalStoreState) => {
      state.tags = { ...initialGlobalStoreState.tags };
      state.profiles = { ...initialGlobalStoreState.profiles };
    },
    [GlobalStoreActions.SET_SHOW_ERROR_BOUNDARY]: (state:IGlobalStoreState, action:PayloadAction<boolean>) => {
      state.showErrorBoundary = action.payload;
    },
    [GlobalStoreActions.SET_SELECTED_PAGE]: (state:IGlobalStoreState, action:PayloadAction<string>) => {
      state.selectedPage = action.payload;
    },
    [GlobalStoreActions.SET_DEVICE_CONTEXT]: (state:IGlobalStoreState, action:PayloadAction<DeviceType>) => {
      state.deviceType = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getTags fetch api
    builder.addCase(getAllTagsRequest.pending, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.GET_ALL_TAGS_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getAllTagsRequest.fulfilled, (state:IGlobalStoreState, action:PayloadAction<Array<ITagsData>>) => {
      state.requestProcessing[GlobalStoreActions.GET_ALL_TAGS_REQUEST] = APIRequestStatus.Success;
      state.tags.globaltags = action.payload;
      state.tags.updatedTagsList = getTagsList(action.payload);
      state.tags.activeTagsList = getTagsList(action.payload);
    });
    builder.addCase(getAllTagsRequest.rejected, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.GET_ALL_TAGS_REQUEST] = APIRequestStatus.Failure;
    });

    // getProfilesList fetch api
    builder.addCase(getProfilesListRequest.pending, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.GET_PROFILE_LIST_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getProfilesListRequest.fulfilled, (state:IGlobalStoreState, action:PayloadAction<IProfileItem[]>) => {
      state.requestProcessing[GlobalStoreActions.GET_PROFILE_LIST_REQUEST] = APIRequestStatus.Success;
      state.profiles.availableProfiles = action.payload || [];
      state.profiles.updatedProfilesList = getProfileList(action.payload);
    });
    builder.addCase(getProfilesListRequest.rejected, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.GET_PROFILE_LIST_REQUEST] = APIRequestStatus.Failure;
    });

    // editTagsByObject fetch api
    builder.addCase(editTagsByObjectRequest.pending, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.EDIT_TAGS_BY_OBJECTID_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(editTagsByObjectRequest.fulfilled, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.EDIT_TAGS_BY_OBJECTID_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(editTagsByObjectRequest.rejected, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.EDIT_TAGS_BY_OBJECTID_REQUEST] = APIRequestStatus.Failure;
    });

    // editProfilesToDashboard fetch api
    builder.addCase(editProfilesToDashboardRequest.pending, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.EDIT_PROFILES_TO_DASHBOARD_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(editProfilesToDashboardRequest.fulfilled, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.EDIT_PROFILES_TO_DASHBOARD_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(editProfilesToDashboardRequest.rejected, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.EDIT_PROFILES_TO_DASHBOARD_REQUEST] = APIRequestStatus.Failure;
    });

    // editProfileToReportsRequest fetch api
    builder.addCase(editProfileToReportsRequest.pending, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.EDIT_PROFILES_TO_REPORTS_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(editProfileToReportsRequest.fulfilled, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.EDIT_PROFILES_TO_REPORTS_REQUEST] = APIRequestStatus.Success;
    });
    builder.addCase(editProfileToReportsRequest.rejected, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.EDIT_PROFILES_TO_REPORTS_REQUEST] = APIRequestStatus.Failure;
    });

    // getTagsByObjectIdRequest fetch api
    builder.addCase(getTagsByObjectIdRequest.pending, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.GET_TAGS_BY_OBJECT_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getTagsByObjectIdRequest.fulfilled, (state:IGlobalStoreState, action:PayloadAction<TagByObject>) => {
      state.requestProcessing[GlobalStoreActions.GET_TAGS_BY_OBJECT_REQUEST] = APIRequestStatus.Success;
      state.tags.globaltags = [...action.payload.TagMetaData];
      const tagList = getEditTagsList(action.payload);
      state.tags.updatedTagsList = tagList;
      state.tags.activeTagsList = tagList;
    });
    builder.addCase(getTagsByObjectIdRequest.rejected, (state:IGlobalStoreState) => {
      state.requestProcessing[GlobalStoreActions.GET_TAGS_BY_OBJECT_REQUEST] = APIRequestStatus.Failure;
    });
  },
});

export const {
  [GlobalStoreActions.UPDATE_NAVBAR_COLLAPSE]: updateNavBarCollapse,
  [GlobalStoreActions.UPDATE_TAG_SELECTION]: updateTagSelectionData,
  [GlobalStoreActions.UPDATE_PROFILE_SELECTION]: updateProfileSelectionData,
  [GlobalStoreActions.RESET_PROFILE_SELECTION]: resetProfileListsSelection,
  [GlobalStoreActions.RESET_TAGS_SELECTION]: resetTagsListsSelection,
  [GlobalStoreActions.RESET_GLOBAL_TAGS_AND_PROFILES]: resetGlobalTagsAndProfiles,
  [GlobalStoreActions.SET_SHOW_ERROR_BOUNDARY]: setShowErrorScreen,
  [GlobalStoreActions.SET_SELECTED_PAGE]: setSelectedPage,
  [GlobalStoreActions.SET_DEVICE_CONTEXT]: setDeviceType,
} = globalStoreSlice.actions;

export default globalStoreSlice;
