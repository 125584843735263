import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIRequestStatus } from 'core/constants/redux';
import {
  getDashboardLinkedReportIds,
  getIsAllSelectedDashboardStatusFilteredBySearchKey,
  getIsAllSelectedReportStatusFilteredBySearchKey,
  getReportSections,
  getUpdatedDashboardSection,
  getUpdatedReportSection,
  updateAccordInfo,
  updateDashboardSectionGroupsOnChangeOfFilterKey,
  updateProfileDashboardSectionCheckbox,
  updateProfileDashboardSelection,
  updateProfileReportSectionCheckbox,
  updateReportSectionBasedReport,
  updateReportSectionGroupsOnChangeOfFilterKey,
  updateSelectedAccord,
} from 'redux-v2/profile/profile.reducer.util';
import { LoadingStatus } from 'core/constants/dashboard';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import {
  IGetVisibilityResponseValue,
  IProfileFormValue,
  IProfileItem,
  IProfileVisibilityConfig,
  ISelectedReport,
  IUpdateProfileFormValue,
  ProfileCompletedActions,
  ProfileVisibleOption,
} from 'core/models/profile';
import { isWrongInput } from 'core/utils/report.util';
import { IDashboardSection, IReportSection, ListingSection } from 'redux-v2/report-listing/report-listing.state';
import { getDashboardSection } from 'redux-v2/report-listing/report-listing.util';
import { isValidArrayAndNotEmpty, isValidObjectAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import {
  IProfileState,
  IGetReportDashboardListSuccess,
  ProfileActions,
  IGetReportListSuccess,
} from './profile.state';
import initialProfileStore from './profile.initial-state';
import {
  deleteProfile,
  getAllDashboardForProfile,
  getAllReportsForProfile,
  getProfileInfoByProfileId,
  getProfileVisibilityValue,
  getProfilesList,
  getVisibilityConfig,
  saveProfileForm,
  updateProfileForm,
} from './profile.requests';

const profileStoreSlice = createSlice({
  name: 'profileStore',
  initialState: initialProfileStore,
  reducers: {
    [ProfileActions.SET_EDIT_MODE_FOR_PROFILE]: (state: IProfileState, action: PayloadAction<boolean>) => {
      state.isEditMode = action.payload;
    },
    [ProfileActions.SET_COMPLETED_PROFILE_ACTION]: (state: IProfileState, action: PayloadAction<ProfileCompletedActions>) => {
      state.completedProfileAction = action.payload;
    },
    [ProfileActions.UPDATE_PROFILE_DETAIL_FORM]: (state: IProfileState, action: PayloadAction<IUpdateProfileFormValue>) => {
      const {
        SelectionPayload,
        formValue: {
          Name, VisibilityMode, Accord, Reports,
        },
      } = action.payload;

      const dashboardLinkedReportIds = !isEmpty(action.payload)
        && getDashboardLinkedReportIds(state, SelectionPayload);
      const setLinkedReportIds = new Set([...dashboardLinkedReportIds]);
      const updateProfileList = state.availableProfiles.filter(
        (item) => item.Id !== state.apiProfileFormValue._id,
      );
      state.profileDetailInfo.selectedAccord = updateSelectedAccord(Accord, state);
      state.profileListing.reportSections = updateReportSectionBasedReport(
        state,
        SelectionPayload,
      );
      state.profileListing.dashboardSections = state.profileListing.selectedProfileTab
      !== ListingSection.Dashboard || isEmpty(SelectionPayload)
        ? [...state?.profileListing?.dashboardSections]
        : updateProfileDashboardSelection(state, SelectionPayload);

      const updatedApiProfileFormValue = cloneDeep(state.apiProfileFormValue);
      if (isValidArrayAndNotEmpty(action.payload.formValue.Reports)) {
        action.payload.formValue.Reports.forEach((item: ISelectedReport) => {
          const currentReportIndex = updatedApiProfileFormValue.Reports.findIndex((report: ISelectedReport) => report.Id === item.Id);
          if (currentReportIndex !== -1 && Object.prototype.hasOwnProperty.call(item, 'AdjoinedByDashboard')) {
            updatedApiProfileFormValue.Reports[currentReportIndex].AdjoinedByDashboard = item.AdjoinedByDashboard;
          }
        });
      }
      if (Object.prototype.hasOwnProperty.call(action.payload.formValue, 'IsAllPinned')) {
        updatedApiProfileFormValue.IsAllPinned = action.payload.formValue.IsAllPinned;
      }
      if (isValidObjectAndNotEmpty(action.payload?.formValue?.Accord)) {
        const updatedAccord = {} as { [key: string]: Array<string> };
        Object.keys(action.payload.formValue.Accord)?.forEach((key: string) => {
          updatedAccord[key] = [...action.payload.formValue.Accord[key]].sort();
        });
        action.payload.formValue.Accord = updatedAccord;
      }
      const isSaveDisabled = isWrongInput(Name)
      || !Name.trim().length
      || isEmpty(Reports)
      || isEqual(action.payload.formValue, updatedApiProfileFormValue)
      || !!updateProfileList.find(
        (item: IProfileItem) => item.Name.trim().toLowerCase() === Name.trim().toLowerCase(),
      )
      || (VisibilityMode === ProfileVisibleOption.SpecificSet
        && Object.values(Accord).every(
          (accordValue: Array<string>) => accordValue.length === 0,
        ));

      state.profileForm.isDisabled = isSaveDisabled;

      state.profileForm.value = {
        ...state.profileForm.value,
        ...action.payload.formValue,
        Reports: Reports?.map((report: ISelectedReport) => ({
          ...report,
          AdjoinedByDashboard: isEmpty(SelectionPayload)
            ? report.AdjoinedByDashboard
            : setLinkedReportIds.has(report.Id),
        })),
      };
    },
    [ProfileActions.UPDATE_PROFILE_SECTION_SELECTION]: (state: IProfileState) => {
      state.profileListing.reportSections = state.profileListing.selectedProfileTab === ListingSection.Report
        ? updateProfileReportSectionCheckbox(state)
        : [...state.profileListing.reportSections];
      state.profileListing.dashboardSections = state.profileListing.selectedProfileTab === ListingSection.Dashboard
        ? updateProfileDashboardSectionCheckbox(state)
        : [...state.profileListing.dashboardSections];
    },
    [ProfileActions.UPDATE_PROFILE_ALL_SECTION]: (state: IProfileState) => {
      if (state?.profileListing?.reportSections) {
        state.profileListing.isAllLinkedReportsSelected = state.profileListing.reportSections.every(
          (section: IReportSection) => section.isSectionLinkedReportsSelected === true,
        );
      }
      if (state?.profileListing?.selectedProfileTab) {
        state.profileListing.isProfileAllDashboardSelected = state.profileListing.selectedProfileTab
        === ListingSection.Dashboard
        && state.profileListing.dashboardSections.every(
          (section: IDashboardSection) => section.isProfileSectionSelected === true,
        );
      }
      state.profileListing.isProfileAllReportSelected = state.profileListing.selectedProfileTab === ListingSection.Report
          && state.profileListing.reportSections.every(
            (section: IReportSection) => section.isProfileSectionSelected === true,
          );
    },
    [ProfileActions.RESET_PROFILE_DETAIL_FORM]: (state: IProfileState) => {
      state.profileListing = initialProfileStore.profileListing;
      state.profileForm = initialProfileStore.profileForm;
      state.apiProfileFormValue = initialProfileStore.apiProfileFormValue;
      state.isEditMode = false;
      state.requestProcessing[ProfileActions.GET_PROFILE_INFO_BY_PROFILE_ID] = APIRequestStatus.Processing;
    },
    [ProfileActions.SET_SELECTED_PROFILE_ID]: (state: IProfileState, action: PayloadAction<IProfileItem>) => {
      state.selectedProfileId = action.payload;
    },
    [ProfileActions.SET_PROFILE_REPORT_SEARCH_VALUE]: (state: IProfileState, action: PayloadAction<string>) => {
      const reportSearchKey = action.payload;
      state.profileListing.reportSearchKey = reportSearchKey;
      state.profileListing.isProfileAllReportSelected = getIsAllSelectedReportStatusFilteredBySearchKey(
        state,
        reportSearchKey.toLocaleLowerCase(),
      );
      state.profileListing.reportSections = updateReportSectionGroupsOnChangeOfFilterKey(
        state,
        reportSearchKey.toLocaleLowerCase(),
      );
    },
    [ProfileActions.SET_PROFILE_DASHBOARD_SEARCH_VALUE]: (state: IProfileState, action: PayloadAction<string>) => {
      const dashboardSearchKey = action.payload;
      state.profileListing.dashboardSearchKey = dashboardSearchKey;
      state.profileListing.isProfileAllDashboardSelected = getIsAllSelectedDashboardStatusFilteredBySearchKey(
        state,
        dashboardSearchKey.toLocaleLowerCase(),
      );
      state.profileListing.dashboardSections = updateDashboardSectionGroupsOnChangeOfFilterKey(
        state,
        dashboardSearchKey.toLocaleLowerCase(),
      );
    },
    [ProfileActions.SET_PROFILE_TAB_VALUE]: (state: IProfileState, action:PayloadAction<ListingSection>) => {
      const selectedProfileTab = action.payload;
      state.profileListing.selectedProfileTab = selectedProfileTab;
    },
    [ProfileActions.UPDATE_PROFILES_LIST]: (state: IProfileState, action: PayloadAction<string>) => {
      state.availableProfiles = state?.availableProfiles?.filter(
        (item: IProfileItem) => item.Id !== action.payload,
      );
      state.profileDeleteLoading = LoadingStatus.Loading;
      state.deleteId = '';
    },
    [ProfileActions.DELETE_PROFILE_ANIMATION]: (state: IProfileState, action: PayloadAction<string>) => {
      state.deleteId = action.payload;
    },
    [ProfileActions.CLEAR_PROFILE_LIST]: (state: IProfileState) => {
      state.availableProfiles = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllReportsForProfile.pending, (state: IProfileState) => {
      state.requestProcessing[ProfileActions.GET_PROFILE_INFO_BY_PROFILE_ID] = APIRequestStatus.AboutToLoad;
      state.profileListing.loadingStatus = LoadingStatus.Loading;
    });
    builder.addCase(getAllReportsForProfile.fulfilled, (state: IProfileState, action: PayloadAction<IGetReportListSuccess>) => {
      state.profileListing.loadingStatus = LoadingStatus.Success;
      state.profileListing.reportSections = getReportSections(action.payload.reportData, action.payload.globalTags);
    });
    builder.addCase(getAllReportsForProfile.rejected, (state: IProfileState) => {
      state.profileListing.loadingStatus = LoadingStatus.Error;
    });

    builder.addCase(getAllDashboardForProfile.pending, (state: IProfileState) => {
      state.profileListing.dashboardListLoadingStatus = LoadingStatus.Loading;
    });
    builder.addCase(getAllDashboardForProfile.fulfilled, (state: IProfileState, action: PayloadAction<IGetReportDashboardListSuccess>) => {
      state.profileListing.dashboardListLoadingStatus = LoadingStatus.Success;
      state.profileListing.dashboardSections = getDashboardSection(
        action.payload.dashboardListData || [],
        action.payload.globalTags,
        true,
      );
    });
    builder.addCase(getAllDashboardForProfile.rejected, (state: IProfileState) => {
      state.profileListing.dashboardListLoadingStatus = LoadingStatus.Error;
    });

    builder.addCase(getVisibilityConfig.pending, (state: IProfileState) => {
      state.profileDetailInfo.loadingStatus = LoadingStatus.Loading;
    });
    builder.addCase(getVisibilityConfig.fulfilled, (state: IProfileState, action: PayloadAction<IProfileVisibilityConfig[]>) => {
      state.profileDetailInfo.profileVisibleConfig = [...action.payload].map((item) => ({
        ...item,
        LoadingState: LoadingStatus.Loading,
      }));
    });
    builder.addCase(getVisibilityConfig.rejected, (state: IProfileState) => {
      state.profileDetailInfo.loadingStatus = LoadingStatus.Error;
    });

    builder.addCase(getProfileVisibilityValue.pending, (state: IProfileState) => {
      state.profileDetailInfo.loadingStatus = LoadingStatus.Loading;
    });
    builder.addCase(getProfileVisibilityValue.fulfilled, (state: IProfileState, action: PayloadAction<IGetVisibilityResponseValue>) => {
      state.profileDetailInfo.loadingStatus = LoadingStatus.Success;
      state.profileDetailInfo.accord = updateAccordInfo(state, action.payload);
    });
    builder.addCase(getProfileVisibilityValue.rejected, (state: IProfileState) => {
      state.profileDetailInfo.loadingStatus = LoadingStatus.Error;
    });

    builder.addCase(saveProfileForm.pending, (state: IProfileState) => {
      state.profileForm.loadingStatus = LoadingStatus.Loading;
    });
    builder.addCase(saveProfileForm.fulfilled, (state: IProfileState) => {
      state.profileForm.loadingStatus = LoadingStatus.Success;
      state.completedProfileAction = ProfileCompletedActions.Add;
    });
    builder.addCase(saveProfileForm.rejected, (state: IProfileState) => {
      state.profileForm.loadingStatus = LoadingStatus.Error;
    });
    builder.addCase(updateProfileForm.pending, (state: IProfileState) => {
      state.profileForm.loadingStatus = LoadingStatus.Loading;
    });
    builder.addCase(updateProfileForm.fulfilled, (state: IProfileState) => {
      state.profileForm.loadingStatus = LoadingStatus.Success;
      state.completedProfileAction = ProfileCompletedActions.UPDATE;
    });
    builder.addCase(updateProfileForm.rejected, (state: IProfileState) => {
      state.profileForm.loadingStatus = LoadingStatus.Error;
    });

    builder.addCase(getProfilesList.pending, (state: IProfileState) => {
      state.profileListLoading = LoadingStatus.Loading;
    });
    builder.addCase(getProfilesList.fulfilled, (state: IProfileState, action: PayloadAction<IProfileItem[]>) => {
      state.availableProfiles = action.payload || [];
      state.profileListLoading = LoadingStatus.Success;
    });
    builder.addCase(getProfilesList.rejected, (state: IProfileState) => {
      state.profileListLoading = LoadingStatus.Error;
    });

    builder.addCase(getProfileInfoByProfileId.pending, (state: IProfileState) => {
      state.requestProcessing[ProfileActions.GET_PROFILE_INFO_BY_PROFILE_ID] = APIRequestStatus.Processing;
    });
    builder.addCase(getProfileInfoByProfileId.fulfilled, (state: IProfileState, action: PayloadAction<IProfileFormValue>) => {
      state.profileForm.value = {
        ...action.payload,
        Dashboards: action.payload.Dashboards
          ? [...action.payload.Dashboards]
          : [],
        Reports: action.payload.Reports ? [...action.payload.Reports] : [],
      };
      state.apiProfileFormValue = {
        ...action.payload,
        Dashboards: action.payload.Dashboards
          ? [...action.payload.Dashboards]
          : [],
        Reports: action.payload.Reports ? [...action.payload.Reports] : [],
      };
      state.profileListing.reportSections = getUpdatedReportSection(
        state.profileListing.reportSections,
        action.payload.Reports ? [...action.payload.Reports] : [],
      );
      state.profileListing.dashboardSections = getUpdatedDashboardSection(
        state.profileListing.dashboardSections,
        action.payload.Dashboards ? [...action.payload.Dashboards] : [],
      );
      state.requestProcessing[ProfileActions.GET_PROFILE_INFO_BY_PROFILE_ID] = APIRequestStatus.Success;
    });
    builder.addCase(getProfileInfoByProfileId.rejected, (state: IProfileState) => {
      state.requestProcessing[ProfileActions.GET_PROFILE_INFO_BY_PROFILE_ID] = APIRequestStatus.Failure;
    });

    builder.addCase(deleteProfile.pending, (state: IProfileState) => {
      state.profileDeleteLoading = LoadingStatus.Loading;
    });
    builder.addCase(deleteProfile.fulfilled, (state: IProfileState) => {
      state.profileDeleteLoading = LoadingStatus.Success;
    });
    builder.addCase(deleteProfile.rejected, (state: IProfileState) => {
      state.profileDeleteLoading = LoadingStatus.Error;
    });
  },
});

export const {
  [ProfileActions.SET_EDIT_MODE_FOR_PROFILE]: setEditModeForProfile, // setting edit mode flag true when we are on edit profile
  [ProfileActions.SET_COMPLETED_PROFILE_ACTION]: setCompletedProfileAction, // completing the action of add or edit profile
  [ProfileActions.UPDATE_PROFILE_DETAIL_FORM]: updateProfileDetailForm, // To modify the profile details form
  [ProfileActions.UPDATE_PROFILE_SECTION_SELECTION]: updateProfileSectionSelection, // Dashboard/report section selection
  [ProfileActions.UPDATE_PROFILE_ALL_SECTION]: updateProfileAllSelection, // If all report selected
  [ProfileActions.RESET_PROFILE_DETAIL_FORM]: resetProfileDetailForm, // resetting the profile form

  [ProfileActions.SET_SELECTED_PROFILE_ID]: setSelectedProfile, // select profile from sidebar
  [ProfileActions.SET_PROFILE_REPORT_SEARCH_VALUE]: setProfileReportSearchValue, // seaching the report in profile page
  [ProfileActions.SET_PROFILE_DASHBOARD_SEARCH_VALUE]: setProfileDashboardSearchValue, // set value in searchbar
  [ProfileActions.SET_PROFILE_TAB_VALUE]: setProfileTabValue, // set selected tab of profile page
  [ProfileActions.UPDATE_PROFILES_LIST]: updateProfilesList, // completion of animation on deleting the profile
  [ProfileActions.CLEAR_PROFILE_LIST]: clearProfileList, // clearing the list of profiles
} = profileStoreSlice.actions;

export default profileStoreSlice;
