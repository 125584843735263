import { AuthReduxModel, AuthResponseModel } from 'core/models';
import { MomentLocales, WeekStart } from 'core/constants/auth';
import moment from 'moment-timezone';
import FeatureDefinitions from 'features/featureDefinitions';
import CacheItem from 'core/constants/cache';
import Cache from 'core/cache/cache';
import { IState } from 'core/models/store';
import { FeatureContext } from 'core/constants/common';
import { IAccessibilityMap } from './auth-store-state';

export const processOrgAttributes = (orgAttributes: AuthResponseModel.OrgAttributes): AuthResponseModel.OrgAttributes => {
  const newOrgAttributes: AuthResponseModel.OrgAttributes = { ...orgAttributes };
  if (!Object.values(WeekStart).includes(newOrgAttributes.DefinedWeekStart)) {
    newOrgAttributes.DefinedWeekStart = WeekStart.Monday;
  }

  const globalLocale = moment().locale();
  moment.updateLocale(MomentLocales.Default, {
    parentLocale: globalLocale,
    week: {
      dow: moment.localeData(globalLocale).weekdaysParse(newOrgAttributes.DefinedWeekStart, 'dddd', true) ?? 1, // default to Monday (1)
    },
  });
  moment.locale(globalLocale); // reset locale https://github.com/moment/moment/issues/5410
  return newOrgAttributes;
};

// Get the Disabled Features information based on the reporting disabled and isSieraEnabled Flags.
// @userAttributes: Object which contains the isSieraEnabled and Reporting Disabled Information.
// Returns an array of Disabled features list.
export const getDisabledFeatures = (userAttributes: AuthResponseModel.UserAttributes): string[] => {
  const isReportingDisabled = userAttributes?.IsReportingDisabled;
  const isSieraEnabled = userAttributes?.isSieraEnabled;
  if (isReportingDisabled) {
    return Object.keys(FeatureDefinitions).filter((key) => FeatureDefinitions[key].isReportingDisabled);
  }
  if (!isSieraEnabled) {
    return Object.keys(FeatureDefinitions).filter((key) => FeatureDefinitions[key].isSieraOnly);
  }
  return [];
};

export const setAuthState = (authState:AuthReduxModel.IState, authResponse:AuthResponseModel.Auth | null) :IState => {
  const { auth } = authResponse as any;// as { auth: AuthResponseModel.Auth };
  if (auth) {
    const {
      Token, ExpiresOn, UserAttributes, OrgAttributes,
    } = auth;
    const newOrgAttributes = processOrgAttributes(OrgAttributes);
    Cache.setItem(CacheItem.Auth, Token);
    Cache.setItem(CacheItem.AuthExpiry, ExpiresOn);
    Cache.setItem(CacheItem.UserAttr, UserAttributes);
    Cache.setItem(CacheItem.OrgAttr, newOrgAttributes);
    return ({
      ...authState,
      token: Token,
      expiresOn: ExpiresOn,
      userAttributes: UserAttributes,
      orgAttributes: newOrgAttributes,
      variables: {
        SER_USER_ID: UserAttributes ? UserAttributes.Id : '',
        SER_USER_AUTO_ID: UserAttributes ? UserAttributes.AutoId : 0,
      },
      isReportingDisabled: UserAttributes?.IsReportingDisabled,
      isSieraEnabled: UserAttributes?.isSieraEnabled,
      disabledFeatures: getDisabledFeatures(UserAttributes),
    });
  }
  Cache.clear();
  return ({
    ...authState,
    token: null,
    expiresOn: -1,
    userAttributes: null,
    orgAttributes: null,
  });
};
// to get userType from auth response
export const getUserType = (AccessibilityMap: IAccessibilityMap) :FeatureContext => {
  if (AccessibilityMap?.Sales && AccessibilityMap?.Service) {
    return FeatureContext.Hybrid;
  }
  if (AccessibilityMap?.Service) {
    return FeatureContext.Service;
  }
  return FeatureContext.Sales;
};
