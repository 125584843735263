export enum PanelSourceTypes {
    Default = 'DefaultReportView',
    Custom = 'CustomReportView',
    Shared = 'SharedReportView',
    Report = 'Report',
    EmbeddedUrl = 'EmbeddedUrl'
}

export enum CompactTypes {
    Vertical = 'vertical',
    Horizontal = 'horizontal'
}

export enum LoadingStatus {
    Loading = 0,
    Success,
    Error
}

export enum DashboardBuilderWidgets {
  None = 0,
  AddWidgetPanelContainer,
  ReportWidgetPanelContainer,
  ReportVisualisationWidgetPanelContainer,
  EmbedURLContainer
}

export enum DashboardFilterPanel {
  None=0,
  FilterListPanelContainer,
  CreateFilterPanel,
  PanelSelectionContainer,
}
export enum RedirectSourceType {
  Dashboard ='dashboard',
  Report = 'report',
}

export const noDataTitle = 'No Data to Display';

export const noDataDescription = 'Please update the data and check your dashboard.';

export const defaultRowHeight = 88;

export const defaultWidgetLimit = 25;

export const defaultDashboardFilterLimit = 15;

export const defaultKPIDimensions = {
  minW: 2,
  minH: 1,
  maxW: 3,
  maxH: 1,
};

export const defaultChartDimensions = {
  minW: 3,
  minH: 3,
  maxW: 12,
  maxH: 12,
};

export const defaultTableDimensions = {
  minW: 3,
  minH: 3,
  maxW: 12,
  maxH: 12,
};

export const DashboardURLTypes = {
  personalizeHome: 'personalizeHome',
  isHomeDashboard: 'isHomeDashboard',
  selectedDashboardId: 'selectedDashboardId',
  referral: 'referral',
};

export enum ClickSource {
  Preview ='preview',
  AddWidget = 'addwidget',
}
export const NumericDataTypes = ['Int', 'Int64', 'Decimal', 'Int64Array'];
export const DateDataTypes = ['Date', 'Time', 'DateTime'];

export enum QueryParamNames {
  isCloneDashboard = 'cloneDashboard',
}
