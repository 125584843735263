import styled from 'styled-components';

const StyledSecondaryButton = styled.div`

    button {
      flex: 1;
      margin: 0 6px;
      padding: 7px 16px;
      font-size: 12px;
      font-family: Regular;
      color: var(--secondaryColor);
      border-radius: 2px;
      font-family: SemiBold;
      outline: none;

      &.se-secondary-changes {
        color: var(--secondaryColor);
        background-color: var(--white);
        border: 1px solid var(--searchBorder);
        border-radius: 2px;

        :hover {
          background-color:var(--clearFilterColor);
        }

        :active {
          background-color:var(--clearFilterColor);
          border: 1px solid var(--searchHoverBorder);
        }

        &.se-button-disabled{
          color: var(--secondaryColor);
          opacity: 0.6;
          cursor: not-allowed;
          border: 1px solid var(--searchBorder);
          :hover{
            background-color: var(--white) !important;
          }
        }
      }
    }

`;

export default StyledSecondaryButton;
