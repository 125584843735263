import { createAsyncThunk } from '@reduxjs/toolkit';
import ScheduleBuilderService from 'services/schedule-builder.service';
import { IScheduleBuilderPayload, IScheduleListPayload, ScheduleBuilderActionTypes } from './schedule-builder.state';

export const saveScheduleConfig = createAsyncThunk(ScheduleBuilderActionTypes.SAVE_SCHEDULE_CONFIG,
  async (payload: IScheduleBuilderPayload) => new ScheduleBuilderService().createScheduleReport(payload));

export const updateScheduleConfig = createAsyncThunk(ScheduleBuilderActionTypes.UPDATE_SCHEDULE_INFO,
  async (payload: IScheduleBuilderPayload) => new ScheduleBuilderService().updateScheduleReport(payload));

export const getScheduleLimits = createAsyncThunk(ScheduleBuilderActionTypes.GET_SCHEDULE_LIMITS,
  async () => new ScheduleBuilderService().getScheduleLimits());

export const getScheduleList = createAsyncThunk(ScheduleBuilderActionTypes.GET_SCHEDULE_LIST,
  async (payload: IScheduleListPayload) => new ScheduleBuilderService().getScheduleList(payload));

export const getScheduleReportNames = createAsyncThunk(ScheduleBuilderActionTypes.GET_SCHEDULE_REPORT_NAMES,
  async () => new ScheduleBuilderService().getScheduleReportNames());

export const getScheduleOwnerNames = createAsyncThunk(ScheduleBuilderActionTypes.GET_SCHEDULE_OWNER_NAMES,
  async () => new ScheduleBuilderService().getScheduleOwnerNames());

export const deactivateSchedule = createAsyncThunk(ScheduleBuilderActionTypes.DEACTIVATE_SCHEDULE,
  async (scheduleId: string) => new ScheduleBuilderService().deactivateSchedule(scheduleId));

export const getScheduleHistory = createAsyncThunk(ScheduleBuilderActionTypes.GET_SCHEDULE_HISTORY,
  async (scheduleId: string) => new ScheduleBuilderService().getScheduleHistory(scheduleId));
