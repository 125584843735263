import { createGlobalStyle } from 'styled-components';
import 'rc-tooltip/assets/bootstrap_white.css';

const GlobalStyle = createGlobalStyle<DefaultProps>`

@font-face {
    font-family: Regular;
    src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeui.ttf") format("truetype");
  }
  
  @font-face {
    font-family: Bold;
    src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeuib.ttf") format("truetype");
  }
  
  @font-face { 
    font-family: Light;
    src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeuil.ttf") format("truetype"); 
  }
  
  @font-face {
    font-family: SemiBold;
    src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/seguisb.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Brandon Grotesque"; 
    src: url("https://db.onlinewebfonts.com/t/6cec834e2e8f2e4206567ed75e1281d4.ttf") format("truetype");
}
  body {
    margin: 0;
    font-family:"Regular", "sans-serif";
    background:${(props) => props.theme.white};
  }
  :root{
    --base-transparent:transparent;
    --base-filter-label-color:${(props) => props.theme.labelColor};
    --border:${(props) => props.theme.border};
    --backButtonColor: ${(props) => props.theme.backButtonColor};
    --fullLoaderBar1:${(props) => props.theme.fullLoaderBar1};
    --fullLoaderBar2:${(props) => props.theme.fullLoaderBar2};
    --fullLoaderBar3:${(props) => props.theme.fullLoaderBar3};
    --fullLoaderBar4:${(props) => props.theme.fullLoaderBar4};
    --white:${(props) => props.theme.white};
    --noDataColor: ${(props) => props.theme.noDataColor};
    --paginationBorder:${(props) => props.theme.paginationBorder};
    --paginationRecordsSeparator:${(props) => props.theme.paginationRecordsSeparator};
    --loaderColor:${(props) => props.theme.loaderColor};
    --searchBorder:${(props) => props.theme.searchBorder};
    --searchHoverBorder:${(props) => props.theme.searchHoverBorder};
    --searchBoxTextColor:${(props) => props.theme.searchBoxTextColor};
    --searchButtonBackground:${(props) => props.theme.searchButtonBackground};
    --backgroundColor:${(props) => props.theme.backgroundColor};
    --paginationPrev:${(props) => props.theme.paginationPrev};
    --paginationNext:${(props) => props.theme.paginationNext};
    --arrowIcon:${(props) => props.theme.arrowIcon};
    --reactOnOptionSelect:${(props) => props.theme.reactOnOptionSelect};
    --reactSelectScrollBar:${(props) => props.theme.reactSelectScrollBar};
    --rawDataHeader:${(props) => props.theme.rawDataHeader};
    --rawDataButton:${(props) => props.theme.rawDataButton};
    --boxShadow:${(props) => props.theme.boxShadow};
    --backButtonBorder:${(props) => props.theme.backButtonBorder};
    --sideNavBarBorder:${(props) => props.theme.sideNavBarBorder};
    --navSectionIcon:${(props) => props.theme.navSectionIcon};
    --headerBackgroundColor:${(props) => props.theme.headerBackgroundColor};
    --avtarColor:${(props) => props.theme.avtarColor};
    --primaryColor:${(props) => props.theme.primaryColor};
    --secondaryColor:${(props) => props.theme.secondaryColor};
    --dangerColor:${(props) => props.theme.dangerColor};
    --expandListButtonHoverColor:${(props) => props.theme.expandListButtonHoverColor};
    --paleSkyColor:${(props) => props.theme.paleSkyColor};
    --darkOrangeColor: ${(props) => props.theme.darkOrangeColor};
    --opportunityColor: ${(props) => props.theme.opportunityColor};
    --taskColor: ${(props) => props.theme.taskColor};
    --userColor: ${(props) => props.theme.userColor};
    --labelColor:${(props) => props.theme.labelColor};
    --filterFocusColor: ${(props) => props.theme.filterFocusColor};
    --disablefilterColor:${(props) => props.theme.disablefilterColor};
    --clearFilterColor:${(props) => props.theme.clearFilterColor};
    --filterBackgroundColor:${(props) => props.theme.filterBackgroundColor};
    --borderColor: ${(props) => props.theme.borderColor};
    --inactiveColor: ${(props) => props.theme.inactiveColor};
    --warningColor: ${(props) => props.theme.warningColor};
    --disclaimerColor: ${(props) => props.theme.disclaimerColor};
    --itemColor: ${(props) => props.theme.itemColor};
    --dangerIconColor: ${(props) => props.theme.dangerIconColor};
    --datePickerWarningColor: ${(props) => props.theme.datePickerWarningColor};
    --wildSand: ${(props) => props.theme.wildSand};
    --dustyGray: ${(props) => props.theme.dustyGray};
    --gallery: ${(props) => props.theme.gallery};
    --chartBackground:${(props) => props.theme.chartBackground};
    --chartBaseColor:${(props) => props.theme.chartBaseColor};
    --blueBorderColor: ${(props) => props.theme.blueBorderColor};
    --dashboardHoverColor: ${(props) => props.theme.dashboardHoverColor};
    --animationGreenColor: ${(props) => props.theme.animationGreenColor};
    --lightGray: ${(props) => props.theme.lightGray};
    --dividerColor: ${(props) => props.theme.dividerColor};
    --dangerColorBg: ${(props) => props.theme.dangerColorBg};
    --animationGreenColorBg: ${(props) => props.theme.animationGreenColorBg};
    --skyBlue:  ${(props) => props.theme.skyBlue};
    --itemColorBg: ${(props) => props.theme.itemColorBg};
    --skyBlue:  ${(props) => props.theme.skyBlue};
    --subTitleBg: ${(props) => props.theme.subTitleBg};
    --skyBlue:  ${(props) => props.theme.skyBlue};
    --scheduledReportColor: ${(props) => props.theme.scheduledReportColor};
    --spinnerLoaderColor: ${(props) => props.theme.spinnerLoaderColor};
    --whiteBackground: ${(props) => props.theme.whiteBackground};
    --lsqDataHeader: ${(props) => props.theme.lsqDataHeader};
    --lsqText: ${(props) => props.theme.lsqText};
    --lsqText2: ${(props) => props.theme.lsqText2};
    --lsqButton: ${(props) => props.theme.lsqButton};
    --lsqToastBackground: ${(props) => props.theme.lsqToastBackground};
    --lsqToastBoard: ${(props) => props.theme.lsqToastBoard};
    --baseDatePickerBorder: ${(props) => props.theme.baseDatePickerBorder};
    --purpleColor:  ${(props) => props.theme.purpleColor};
    --lsqHover:  ${(props) => props.theme.lsqHover};
    --lsqBorder:  ${(props) => props.theme.lsqBorder};
    --lsqSelected:  ${(props) => props.theme.lsqSelected};
    --lsqAltColor:  ${(props) => props.theme.lsqAltColor};
    --lsqAltColor2:  ${(props) => props.theme.lsqAltColor2};
    --lsqSelHover:  ${(props) => props.theme.lsqSelHover};
    --lsqIconFilled:  ${(props) => props.theme.lsqIconFilled};
    --lsqTagCol:  ${(props) => props.theme.lsqTagCol};
    --lsqBgCol:  ${(props) => props.theme.lsqBgCol};
    --lsqDeleteBtn:  ${(props) => props.theme.lsqDeleteBtn};
    --lsqSectionHover:  ${(props) => props.theme.lsqSectionHover};
    --v1animationColor: ${(props) => props.theme.v1animationColor};
    --lsqDeleteCount: ${(props) => props.theme.lsqDeleteCount};
    --lsqAllRptColor: ${(props) => props.theme.lsqAllRptColor};
    --backgroundColorSearchV1: ${(props) => props.theme.backgroundColorSearchV1};
    --backgroundColorDropdown: ${(props) => props.theme.backgroundColorDropdown};
    --bgColorExpTooltip: ${(props) => props.theme.bgColorExpTooltip};
    --bannerFontColor: ${(props) => props.theme.bannerFontColor};
    --bannerBgColor: ${(props) => props.theme.bannerBgColor};
    --bannerButtonsHover: ${(props) => props.theme.bannerButtonsHover};
    --dropdownBoxShadow :  ${(props) => props.theme.dropdownBoxShadow};
    --lineSeprator: ${(props) => props.theme.lineSeprator};
    --leadsquaredIconBlue:  ${(props) => props.theme.leadsquaredIconBlue};
    --leadsquaredIconBlack: ${(props) => props.theme.leadsquaredIconBlack};
    --leadsquaredLightBlue: ${(props) => props.theme.leadsquaredLightBlue};
    --customEntiyColor: ${(props) => props.theme.customEntiyColor};
    --boxShadowColor: ${(props) => props.theme.boxShadowColor};
    --siennaColor: ${(props) => props.theme.siennaColor};
    --ticketEntityColor: ${(props) => props.theme.ticketEntityColor};
    --mergeJobHighlightColor: ${(props) => props.theme.mergeJobHighlightColor};
    --customIconColor: ${(props) => props.theme.customIconColor};
    --ticketActivityEntity: ${(props) => props.theme.ticketActivityEntity};
    --rowFooterBackgroundColor: ${(props) => props.theme.rowFooterBackgroundColor};
    --accountsEntity: ${(props) => props.theme.accountsEntity};
    --accountsActivityEntity: ${(props) => props.theme.accountsActivityEntity};
    --webContentEntity: ${(props) => props.theme.webContentEntity};
    --cornflowerBlueColor: ${(props) => props.theme.cornflowerBlueColor};
    --reactCalenderTileDisableColor: ${(props) => props.theme.reactCalenderTileDisableColor};
    --dangerHoverColor: ${(props) => props.theme.dangerHoverColor};
    --scrollbarColor: ${(props) => props.theme.scrollbarColor};
    --tableHeaderBackgroundColor: ${(props) => props.theme.tableHeaderBackgroundColor};
    --iconStroke:${(props) => props.theme.iconStroke}
  }
  
  @media (min-width: 768px) {
    height: auto;
  }

  @media (min-width: 1024px) {
    height: auto;
  }

  button {
    cursor: pointer;
    background: transparent;
  }

  button:disabled {
    cursor: default;
  }
  .se-shimmer-cn {
    background: none !important;
    padding: 0 !important;
    border: 0 !important;
    pointer-events: none !important;
  }

  .text-ellipsis{
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .se-shimmer {
    width: 100%;
    height: 100%;
    background: var(--backgroundColor);
    background-image: linear-gradient(to right, var(--backgroundColor) 0%, #dfe3e8 20%, var(--backgroundColor) 40%, var(--backgroundColor) 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;  

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
  }

  @-webkit-keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    
    100% {
      background-position: 468px 0; 
    }
  }


.se-icon-cn {
  display: flex;
  align-items: center;
}

.se-custom-scrollbar {
  overflow: auto !important;
}


.se-custom-scrollbar::-webkit-scrollbar {
  background-color: white;
  width: 6px;
}

.se-custom-scrollbar:hover::-webkit-scrollbar {
  visibility: visible !important;
}

.se-custom-scrollbar::-webkit-scrollbar {
  visibility: hidden;
} 
 
.se-custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius:8px;
  cursor:pointer;
}
  
.se-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color:transparent;
  cursor:pointer;
  border-radius:3px;
}
.externalAppIntegrationStlye{
  padding-bottom: 45px;
 } 
 
.se-custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #ADBAC7;
  cursor:pointer;
  border-radius:3px;
}

.se-text-btn {
  display: flex;
  align-items: center;
  border: 0;
  padding: 0;
}

.rc-tooltip-inner{
  min-height: 22px !important;
  padding: 0 10px !important;
  color: var(--secondaryColor) !important;
  text-align: left;
  text-decoration: none;
  background-color: var(--backgroundColor) !important;
  border-radius: 3px;
  border: 1px solid var(--backgroundColor) !important;
  box-shadow: 0px 3px 4px var(--boxShadow);
  }
  
  .rc-tooltip:not(.se-dim-tooltip) {
  
    .rc-tooltip-arrow{
      display: none;
    } 
  }

  .rc-tooltip.se-dimension-item-info {
    background: #fff !important;
    opacity: 1;
    .rc-tooltip-inner {
      max-width: 120px;
      color: #F38037 !important;
      background: #fff !important;
      padding: 10px !important;
      opacity: 1;
    }

    .rc-tooltip-arrow {
      display: block;
      top: 5%;
      border-left-color: var(--backgroundColor)  
    }
  }

  .rc-tooltip.se-dimension-item-grouping-limit-tooltip.hide {
    display: none;
  }

  .se-cursor-not-allowed {
    cursor: not-allowed;
  }

  .rc-tooltip.se-restricted-field {
    z-index: 9999;
    .rc-tooltip-inner {
    color: #FFFFFF !important;
    background-color: var(--primaryColor) !important;
    padding: 10px 8px 10px 8px !important;  
    font-size: 13px !important;
    white-space: nowrap;
  }
  }

  .rc-tooltip.se-breadcrumbs-tooltip {
    .rc-tooltip-inner {
    color: #FFFFFF !important;
    background-color: var(--primaryColor) !important;
    padding: 5px 8px 5px 8px !important;  
    font-size: 12px !important;
    white-space: nowrap;
    opacity: 1;
    }
  }

  .se-dark-theme-tooltip, .se-filter-linking-tooltip, .se-export-name-tooltip, .se-measure-form-tooltip, .se-grouping-tooltip, .se-string-field-warning-icon-tooltip,.se-sales-group-uesr-field,
   .se-dashboard-panel-tooltip, .se-row-group-sort, .se-dashboard-row-group  .se-sort-tooltip, .se-filter-tooltip, .se-filter-desc-tooltip, .se-profile-tooltip, .se-section-item-name-tooltip, .se-save-dark-theme-tooltip, .se-edit-report-tooltip{
    opacity: 1;
    z-index: 9999;
    padding:0px;
    .rc-tooltip-inner {
      color: #FFFFFF !important;
      background-color: var(--primaryColor) !important;
      padding: 9px 12px !important; 
      font-size: 13px !important;
      opacity: 1;
      max-width: 300px;
      word-wrap: break-word;
      display: block;
      border: none !important;
      border-radius: 4px;
      box-shadow: none !important;

      .se-string-field-tooltip-text-container, .se-has-others-col-text-container {
        font-family: Regular;
      }

      .se-string-field-tooltip-disclaimer, .se-has-others-col-tooltip-disclaimer {
        color: var(--disclaimerColor);
      }

      .se-string-field-filter-names {
        color: var(--itemColor);
      }

      .se-string-field-double-quotations {
        color: var(--white);
      }
      .se-filter-header{
        padding: 0px;
        font-family: SemiBold;
      }
      .se-applied-filters-list{
        padding: 0px 0px 0px 22px;
        margin: 0px;
      }
      .se-highlight-in-orange{
        color: var(--itemColor);
      }
      .se-others-col-tip{
        font-style: italic;
        color: var(--lightGray);
        margin-top: 16px;
      }
    }
    .rc-tooltip-content{
      padding:0px;
    }
  }
  .se-save-dark-theme-tooltip{
     max-width: 288px !important;
  }

  .se-edit-report-tooltip{
     max-width: 400px !important;
  }
  .se-date-range-disclaimer-tooltip{
     max-width: 296px !important;
  }


  .se-v1-tooltip{
    color: #565556;
    background-color: var(--white);
    padding: 0px !important;
    max-width: 500px;
    .rc-tooltip-inner{
      color: #565556 !important;
      border-radius: 0px !important;
      background-color: var(--white) !important;
      padding : 6px 8px !important;
      border: 0px !important;
      font-family: Regular;
    }
    opacity: 1;
  }
  
  .se-hide-tooltip {
    display: none;
  }

  .se-grouping-tooltip{
    max-width: 346px;
  }

  .se-string-field-warning-icon-tooltip {
    .rc-tooltip-inner {
      max-width: 260px !important;
      padding: 12px !important;
    }
  }

  .se-sales-group-uesr-field{
    .rc-tooltip-inner {
      max-width: 340px !important;
      padding: 12px 9px 12px 12px !important;
    }
  }

  .se-measure-form-tooltip{
    border-radius: 2px;

    div {
      display: block;
      padding: 3px 0;
      font-family: Regular;

      &.se-tooltip-entity{
        font-family: SemiBold;
        position: relative;

        &:not(.se-tooltip-entity-none) {
          padding-left: 12px;
          
          &:before{
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 3px;
            position: absolute;
            left: 0;
            top: 10.5px;
            background-color: #fff;
          }
        }

        &.se-tooltip-entity-lead::before{
          background-color: var(--avtarColor);
        }

        &.se-tooltip-entity-activity::before{
          background-color: var(--expandListButtonHoverColor);
        }

        &.se-tooltip-entity-opportunity::before{
          background-color: var(--opportunityColor);
        }

        &.se-tooltip-entity-task::before{
          background-color: var(--taskColor);
        }
        
        &.se-tooltip-entity-user::before{
          background-color: var(--userColor);
        }
      }

      span{
        font-family: SemiBold;
      }
    }
  }
  .se-filer-tooltip {
    opacity: 1;
    z-index: 9999;
  }
  .se-filter-linking-tooltip{
    max-width: 312px !important;
  }
  .hide-button-tooltip {
    display: none !important;
  }
  .show-button-tooltip{
    opacity: 1;
    .rc-tooltip-inner {
      color: #FFFFFF !important;
      background-color: var(--primaryColor) !important;
      padding: 10px 8px 10px 8px !important;  
      font-size: 13px !important;
      opacity: 1;
      box-shadow: none;
    }
  }

  .rc-tooltip.se-Viewindicator {
    .rc-tooltip-inner {
    color: var(--white) !important;
    background-color: var(--primaryColor) !important;
    padding: 10px 8px 10px 8px !important;  
    font-size: 13px !important;
    white-space: nowrap;
    border-radius: 4px;
    top:20px;
  }
  }

  .se-string-filter-warning-tooltip {
      .rc-tooltip-inner {
        background-color: var(--primaryColor) !important;
        color: var(--white) !important;
        padding: 12px !important;
      }
  }
  
  .highcharts-exporting-group {
    display: none;
  }
  .highcharts-tooltip-cn{
    display:flex;
    margin-bottom:4px;
  }
  .highchart-circle-cn{
    padding:6px 4px 0px 4px;
    .point-format {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      }
  }
  
  .highchartooltips{
    color:var(--white);
    padding-left:0px;
    font-size:11px;
    line-height:18px;
    font-family:Regular;
  }
  .value{
    color:var(--white);
    font-size:11px;
    line-height:18px;
    font-family:SemiBold;
    padding-left: 4px;
  }
  .highchart-tooltip-header{
    font-size:13px;
    color:var(--white);
    font-family:SemiBold;
    line-height:18px;
    padding: 4px 4px 6px 4px;
  }

  .se-warning-summary{
    color: var(--itemColor);
  }
  .se-warning-tooltip-divider{
    border: 1px solid var(--dividerColor);
    margin: 12px 0px;
  }

  .se-react-select-cn.se-react-select--is-disabled .se-react-select__control {
    cursor: not-allowed !important;
  }
  .se-tooltip-ctn{
  display: flex;
  flex-direction: column;
  width: 280px;

  .se-tooltip-items{
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
  }
  .header{
    color: var(--searchBoxTextColor);
    font-family: SemiBold;
  }
  .se-bold{
    font-family: SemiBold;
  }
}
`;

export interface DefaultProps {
theme:Itheme
}
export interface Itheme {
  labelColor:string,
  border:string,
  backButtonColor: string,
  loaderColor:string,
  fullLoaderBar1:string,
  fullLoaderBar2:string,
  fullLoaderBar3:string,
  fullLoaderBar4:string,
  white:string,
  noDataColor: string,
  paginationBorder:string,
  paginationRecordsSeparator:string,
  searchBorder:string,
  searchHoverBorder:string,
  searchBoxTextColor:string,
  searchButtonBackground:string,
  backgroundColor:string,
  paginationPrev:string,
  paginationNext:string,
  arrowIcon:string,
  reactOnOptionSelect: string,
  reactSelectScrollBar: string,
  rawDataHeader:string,
  rawDataButton:string,
  boxShadow:string,
  backButtonBorder:string,
  sideNavBarBorder:string,
  navSectionIcon: string,
  headerBackgroundColor:string,
  avtarColor:string,
  primaryColor: string,
  secondaryColor: string,
  dangerColor: string,
  expandListButtonHoverColor: string,
  paleSkyColor: string,
  darkOrangeColor: string;
  opportunityColor: string;
  taskColor:string;
  userColor: string;
  filterFocusColor:string;
  disablefilterColor:string;
  clearFilterColor:string;
  filterBackgroundColor:string;
  borderColor: string;
  inactiveColor: string;
  warningColor: string;
  disclaimerColor: string;
  itemColor: string,
  dangerIconColor: string,
  datePickerWarningColor: string,
  wildSand: string;
  dustyGray: string;
  gallery: string;
  chartBackground: string;
  chartBaseColor: string;
  blueBorderColor: string;
  dashboardHoverColor:string;
  animationGreenColor:string;
  lightGray:string;
  dividerColor:string;
  dangerColorBg: string;
  animationGreenColorBg: string;
  skyBlue: string;
  itemColorBg: string;
  subTitleBg: string;
  scheduledReportColor:string;
  spinnerLoaderColor:string;
  whiteBackground:string;
  lsqDataHeader:string;
  lsqText:string;
  lsqButton:string;
  lsqText2:string;
  lsqToastBackground:string;
  lsqToastBoard:string;
  baseDatePickerBorder:string;
  purpleColor: string;
  lsqHover: string;
  lsqBorder: string;
  lsqSelected: string;
  lsqAltColor: string;
  lsqAltColor2: string;
  lsqSelHover: string;
  lsqIconFilled: string;
  lsqTagCol: string;
  lsqBgCol: string;
  lsqDeleteBtn: string;
  lsqSectionHover: string;
  v1animationColor: string;
  lsqDeleteCount:string;
  lsqAllRptColor: string;
  backgroundColorSearchV1: string;
  backgroundColorDropdown: string;
  bgColorExpTooltip: string;
  bannerFontColor: string;
  bannerBgColor: string;
  bannerButtonsHover: string;
  dropdownBoxShadow: string;
  lineSeprator: string;
  leadsquaredIconBlue: string;
  leadsquaredIconBlack: string;
  leadsquaredLightBlue: string;
  customEntiyColor: string;
  boxShadowColor: string;
  siennaColor: string;
  ticketEntityColor: string;
  mergeJobHighlightColor: string;
  customIconColor: string;
  ticketActivityEntity: string;
  rowFooterBackgroundColor: string;
  accountsEntity: string;
  accountsActivityEntity: string;
  webContentEntity: string;
  cornflowerBlueColor: string;
  reactCalenderTileDisableColor: string;
  dangerHoverColor: string;
  scrollbarColor: string;
  tableHeaderBackgroundColor: string;
  iconStroke: string
}

export default GlobalStyle;
