import axios, { AxiosInstance, AxiosResponse } from 'axios';
import store from 'redux-v2/store';
import lsqMarvinExternalAppIntegrator from '@lsq-marvin-app-utility/external-app-integrator';
import { IContext } from '@lsq-marvin-app-utility/external-app-integrator/build/esm/external-app-integrator/interface';
import { statusCodes } from 'core/constants/report';
import { setLanguageCode } from 'redux-v2/localization/localization.reducer';
import { globalLogger } from 'core/utils/GlobalLogger';
import SieraResponse from 'core/models/siera-response';
import { setAuth } from 'redux-v2/auth-store/auth-store.reducer';
import { MobileAuthExceptions, SourceTypes } from 'core/models/auth-response';

export default class API {
  static redirectUrl = process.env.REACT_APP_AUTH_REDIRECT_URL || '';

  static isLocalizationEnabled = process.env.REACT_APP_LOCALIZATION_ENABLED === 'true' || false;

  instance: AxiosInstance;

  constructor(
    baseURL: string,
    authorizedReq: boolean = true,
    interceptResponse: boolean = true,
  ) {
    this.instance = axios.create({
      baseURL,
    });
    this.onSignOutMarvin();
    if (API.isLocalizationEnabled) {
      this.onLanguageChange();
    }
    const { token, isMarvin } = store.getState().auth;
    if (authorizedReq) {
      if (isMarvin) {
        this.marvinInterceptorRequest();
      } else if ((token && !this.isTokenExpired())) {
        this.interceptRequest();
      } else {
        store.dispatch(setAuth(null));
      }
    }
    if (interceptResponse) {
      this.interceptResponse();
    }
  }

  onSignOutMarvin() {
    lsqMarvinExternalAppIntegrator.eventSubscriber.onSignOut({
      callBackFn: () => {
        localStorage.clear();
      },
    });
  }

  onLanguageChange() {
    lsqMarvinExternalAppIntegrator.eventSubscriber.onLanguageChange({
      callBackFn: (langCode: any) => {
        const language = langCode.language;
        store.dispatch(setLanguageCode(language));
      },
    });
  }

  getMarvinToken(reIssueToken: boolean) {
    return new Promise((resolve) => {
      let marvinToken = '';
      if (!reIssueToken) {
        lsqMarvinExternalAppIntegrator.context.get({
          callBackFn: (data: IContext) => {
            marvinToken = data.authenticationDetails.token;
            const language: any = data.language;
            if (API.isLocalizationEnabled) {
              store.dispatch(setLanguageCode(language.language));
            }
            resolve(marvinToken);
          },
        });
      } else {
        lsqMarvinExternalAppIntegrator.actionDispatcher.reIssueTokens({
          callBackFn: (data) => {
            marvinToken = data.Tokens.Token;
            resolve(marvinToken);
          },
        });
      }
    });
  }

  marvinInterceptorRequest(reIssueToken = false) {
    this.instance.interceptors.request.use(
      async (requestConfig: any) => {
        // Get marvin token - reIssueToken will be passed true if at all the marvin token gets expired
        const { token } = store.getState().auth;
        const marvinToken = await this.getMarvinToken(reIssueToken);
        const config = { ...requestConfig };
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.MARVINTOKEN = marvinToken;
        config.headers.SOURCE = 'MARVIN';
        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  interceptRequest() {
    this.instance.interceptors.request.use(
      (requestConfig) => {
        const { token } = store.getState().auth;
        const config = { ...requestConfig };
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.SOURCE = 'LSQ';
        return config;
      },
    );
  }

  private interceptResponse() {
    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error && error.response && error.response.status === statusCodes.Unauthorized) {
          if (error.response?.data?.ExceptionType !== MobileAuthExceptions.tokenExpired && error.response?.data?.ExceptionType !== MobileAuthExceptions.mismatchToken) {
            store.dispatch(setAuth(null));
          }
        }
        if (error && error.response && error.response.status === statusCodes.Forbidden) {
          return Promise.reject(error.response);
        }
        return Promise.reject(error);
      },
    );
  }

  responsify(response: AxiosResponse) {
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  }

  responsifyGeneric<T>(response: AxiosResponse): T {
    if (response && response.status === statusCodes.Success && response.data) {
      return response.data as T;
    }
    if (response && response.status === statusCodes.NoContent) {
      return {
        Token: '',
        ExpiresOn: 0,
        UserAttributes: null,
        RedirectAttributes: null,
        isTenantDisabled: true,
        statusCodes: response.status,
        data: response.data as T,
      } as unknown as T;
    }
    return null;
  }

  genericResponseWrapper<T>(response: AxiosResponse): SieraResponse<T> {
    const sieraResponse = {} as SieraResponse<T>;
    if (response) {
      sieraResponse.data = response.data ? response.data : undefined;
      sieraResponse.status = response.status ? response.status : -1;
    }
    return sieraResponse;
  }

  logoutApi = async () => {
    const { token } = store.getState().auth;
    if (token) {
      const inst = axios.create({
        baseURL: process.env.REACT_APP_AUTH_BASE_URL || '',
      });
      await inst.get('user/logout', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  };

  logout(redirectUrl: string | null = null, cb: Function | null = null) {
    let redirectTo = API.redirectUrl;
    if (redirectUrl && redirectUrl.length) {
      redirectTo = redirectUrl;
    }
    const { isMarvin, mobileAuthInfo } = store.getState().auth;
    const isMobile = mobileAuthInfo?.source === SourceTypes.IOS || mobileAuthInfo?.source === SourceTypes.ANDROID;
    localStorage.clear();

    if (!isMobile) {
      store.dispatch(setAuth(null));// as store content is required for maintaining mobile context
      clearInterval(store.getState().auth.activeInterval);
    }
    // We are encoding the URl so that we can store the query params after login also and can redirect to particular report view
    const encodedURL = encodeURIComponent(window.location.href);

    this.logoutApi()
      .then(() => {
        if (isMarvin) {
          window.top.location.href = document.referrer;
        } else if (isMobile) {
          if (mobileAuthInfo?.source === SourceTypes.IOS) {
            window.webkit?.messageHandlers?.onSessionExpired?.postMessage(
              JSON.stringify({ exception: MobileAuthExceptions.sessionExpired }),
            );
          } else {
            window.LSQAndroidClient?.onSessionExpired(
              JSON.stringify({ exception: MobileAuthExceptions.sessionExpired }),
            );
          }
        } else {
          window.open(`${redirectTo}${encodedURL}`, '_self');
        }
      })
      .catch(() => {})
      .finally(() => {
        if (cb && typeof cb === 'function') {
          try {
            cb(true);
          } catch (error) {
            //
          }
        }
      });
  }

  isTokenExpired() {
    const { expiresOn, mobileAuthInfo } = store.getState().auth;
    if (expiresOn > new Date().getTime() / 1000) return false;
    if (mobileAuthInfo?.source !== SourceTypes.IOS && mobileAuthInfo?.source !== SourceTypes.ANDROID) {
      store.dispatch(setAuth(null));
    }
    globalLogger('token Expired');
    return true;
  }
}
