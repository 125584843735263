import React, { useState } from 'react';
import { IProps } from 'components/common/Error/error.d';
import StyledContainer from 'components/common/Error/error.style';
import ErrorIcon from 'components/common/svg/Error/error.svg';
import RefreshIcon from 'components/common/svg/Refresh/refresh.svg';
import { useSelector } from 'react-redux';
import authSelector from 'redux-v2/auth-store/auth-store.selector';
import useLocalize from '../Localization/useLocalize.hook';

const Component = (props: IProps) => {
  const {
    message, retry, back, headerMessage, isAutodrilldownError, uniqueMessage, errorIconDimension,
    // tenant, issue,
  } = props;

  const { isV1Report } = useSelector(authSelector);

  return (
    <div
      className={`se-error-showcase ${isV1Report ? 'v1Reports' : ''}`}
    >
      <div
        className="se-error-icon"
      >
        <ErrorIcon
          width={errorIconDimension}
          height={errorIconDimension}
          fill2="var(--secondaryColor)"
        />
      </div>
      <div
        className="se-error-header"
      >
        {headerMessage || useLocalize('cmn.sorrySWW')}
      </div>
      <div
        className="se-error-message"
      >
        {
            uniqueMessage || (
            <>
              {useLocalize('cmn.pleaseTryAgain', [message])}
            </>
            )
          }
      </div>
      <div
        className="se-error-actions"
      >
        {
          retry && (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              if (isAutodrilldownError) {
                retry(true);
              } else {
                retry();
              }
            }}
            className="se-refresh-button"
          >
            <span
              className="se-icon"
            >
              <RefreshIcon
                width={16}
                height={16}
                fill="var(--secondaryColor)"
              />
            </span>
            <span>
              {headerMessage ? useLocalize('cmn.reset') : useLocalize('cmn.tryAgain')}
            </span>
          </button>
          )
        }

        {
          !isV1Report && back && (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                back();
              }}
              className="se-return-button"
            >
              <span>
                {useLocalize('cmn.goToListingPage')}
              </span>
            </button>
          )
        }
      </div>
    </div>
  );
};

const Error = (props: IProps) => {
  const { isDialog, isDroppable, errorWidth } = props;
  const [show, setShow] = useState<boolean>(true);

  if (isDialog) {
    return show
      ? (
        <StyledContainer errorWidth={errorWidth}>
          <button
            type="button"
            className={`se-error-dialog ${isDroppable ? '' : 'non-droppable'}`}
            onClick={() => {
              if (isDroppable) {
                setShow(false);
              }
            }}
          >
            <Component
              {...props}
            />
          </button>
        </StyledContainer>
      )
      : null;
  }

  return (
    <StyledContainer errorWidth={errorWidth}>
      <div
        className="se-error-non-dialog"
      >
        <Component
          {...props}
        />
      </div>
    </StyledContainer>
  );
};

Error.defaultProps = {
  errorIconDimension: 80,
};

export default Error;
