export default abstract class Feature {
    private key: string;

    private disabledFeatures: string[];

    constructor(key: string, disabledFeatures: string[]) {
      this.key = key;
      this.disabledFeatures = disabledFeatures;
    }

   abstract isAccessible (): boolean

   protected isRouteAccessible(): boolean {
     return !this.disabledFeatures.includes(this.key);
   }
}
