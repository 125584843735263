import CacheItem from 'core/constants/cache';
import { AuthResponseModel, StoreModel } from 'core/models';
import Cache from 'core/cache/cache';
import { DeviceType } from 'core/models/store';
import { processOrgAttributes } from './auth-store.util';

const InitialAuthState = (): StoreModel.IState => {
  const token = '';
  const expiresOn = Cache.getItem(CacheItem.AuthExpiry) || -1;
  const userAttributes = Cache.getItem(CacheItem.UserAttr) as AuthResponseModel.UserAttributes;
  const orgAttributes = processOrgAttributes(Cache.getItem(CacheItem.OrgAttr));
  return {
    token,
    expiresOn,
    userAttributes,
    orgAttributes,
    variables: {
      SER_USER_ID: userAttributes ? userAttributes.Id : '',
      SER_USER_AUTO_ID: userAttributes ? userAttributes.AutoId : 0,
    },
    isMarvin: false,
    isV1Report: false,
    statusCode: 200,
    userSettings: {
      showViewMode: null,
    },
    activeInterval: 0,
    isReportingDisabled: false,
    isEmbedded: false,
    settings: null,
    isSieraEnabled: true,
    disabledFeatures: [],
    deviceType: DeviceType.DESKTOP,
    mobileAuthInfo: {},
  };
};
export default InitialAuthState;
