import React from 'react';
import Modal from 'components/common/Modal/modal';
import useLocalize from 'components/common/Localization/useLocalize.hook';
import { WarningIconNew } from 'components/common/svg';
import SecondaryButton from 'components/common/SecondaryButton/secondary-button.component';
import PrimaryButton from 'components/common/PrimaryButton/primary-button.component';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/pendoClassNames';
import StyledDeleteConfirmationModal from './delete-confirmation-modal.styled';

interface IConfirmationModalProps {
  show: boolean;
  onClose: () => void;
  onDeleteConfirmation: () => void;
  modalContent: string;
  optionalContent?: string;
}
const DeleteConfirmationModal = ({
  show,
  onClose,
  onDeleteConfirmation,
  modalContent,
  optionalContent,
}: IConfirmationModalProps) => {
  const titleContent = (
    <div className="se-confirm-delete-title-container">
      <WarningIconNew />
      <div className="se-confirm-title">{useLocalize('cmn.warning')}</div>
    </div>
  );
  const footerContent = (
    <div className="se-confirm-delete-button-container">
      <SecondaryButton
        uniqueKey="se-confirm-delete-button"
        onClickHandler={() => onDeleteConfirmation()}
        displayName={`${useLocalize('cmn.deleteConfirmText')}`}
        classNames={['se-confirm-delete-button', PendoClassNames.ExpDeleteConfirmBtn]}
        tooltip={`${useLocalize('cmn.deleteConfirmText')}`}
        tooltipPlacement="bottom"
      />
      <PrimaryButton
        uniqueKey="se-cancel-delete-button"
        isDisable={false}
        displayName={`${useLocalize('cmn.no')}`}
        tooltipOverlay={<span>{useLocalize('cmn.no')}</span>}
        onClickHandler={() => onClose()}
        classNames={['se-cancel-delete-button', PendoClassNames.ExpCancelDelete]}
        overlayClassName="se-dark-theme-tooltip"
      />
    </div>
  );
  return (
    <StyledDeleteConfirmationModal>
      <Modal
        show={show}
        onClose={onClose}
        title={titleContent}
        footer={footerContent}
        classNames={[PendoClassNames.ExpDeleteCrossBtn]}
      >
        <div className="se-confirm-delete-body">{modalContent}</div>

        {optionalContent && (
          <div className="se-confirm-delete-sub-content">{optionalContent}</div>
        )}
      </Modal>
    </StyledDeleteConfirmationModal>
  );
};
export default DeleteConfirmationModal;
